<template>
  <div class="wrapper-modal-video">
    <div class="modal-video">
      <video id="modal-video"></video>
      <i
        class="close absolute el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        @click="$emit('close')"
      />
      <LoaderIcon v-if="isLoader" id="loading-indicator" class="loader" />
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js'
import LoaderIcon from '../assets/LoaderIcon'

export default {
  name: 'ModalVideo',
  components: {
    LoaderIcon
  },
  props: {
    stream: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isLoader: false,
      hls: null
    }
  },

  mounted() {
    this.isLoader = true
    setTimeout(() => {
      this.hls = new Hls()
      const modalVideo = document.getElementById(`modal-video`)
      this.hls.loadSource(this.stream.url)
      this.hls.attachMedia(modalVideo)
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        modalVideo.play()
      })
      this.isLoader = false
    }, 3000)
  },

  beforeDestroy() {
    this.hls.destroy()
  }
}
</script>

<style lang="stylus" scoped>

.wrapper-modal-video
  position absolute
  top 0
  width 100%
  height 100%
  z-index 30
  background rgba(0, 0, 0, 0.7)
.modal-video
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  z-index 30
  width calc(100% - 268px)
  height calc(100% - 80px)
  background white
  video
    width 100%
    height 100%
  .close
    top 24px
    right 24px
  .loader
    position absolute
    top 50%
    left 50%
</style>
